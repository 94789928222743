import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import InputField from "./InputField";
import Select from "react-select";
import CustomCheckbox from "./CustomCheckbox";
import usePlans from "./usePlans";
import uploadLogo from "../icons/uploadLogo.svg";
import { ReloadIcon } from "@radix-ui/react-icons";
import apiClient, { reactAppSubscriptionKey } from "../../http/common";
import { baseUrl } from "../../utils/helper";

const CreatePlanFormModal = ({
  closeModal,
  handleCheckboxChange,
  errors,
  editPlanObj,
  selectedOptions,
  state,
}) => {
  // console.log(state);
  const { items, arrayAsString, mode, setArrayAsString } = usePlans();
  const [reload, setreload] = useState(false);
  const [hmoDescription, setHmoDescription] = useState("");
  const [planDescription, setPlanDescription] = useState("");
  const [subscriptionPrice, setSubscriptionPrice] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef(null);

  const [fileName, setFileName] = useState(
    fileInputRef?.current?.files[0]?.name
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setSubscriptionPrice(value);
  };

  const acceptedFormat = ["jpeg", "png", "gif", "jpg"];

  const handleFileUpload = async () => {
    setIsLoading(true);
    console.log(subscriptionPrice);
    const formData = new FormData();
    formData.append("SponsorId", state.sponsorId);
    formData.append("PlanId", state.planId);
    formData.append("description", planDescription);
    formData.append("SponsorDescription", hmoDescription);
    formData.append("SponsorLogo", fileInputRef.current.files[0]);
    formData.append("SubscriptionPrice", subscriptionPrice);

    // for (const entry of formData.entries()) {
    //   console.log(`Key: ${entry[0]}, Value: ${entry[1]}`);
    // }
    if (acceptedFormat?.includes(fileName?.split(".")[1])) {
      apiClient
        .post(`/sponsor/PublishPlan`, formData)
        .then((response) => {
          console.log(response);
          if (response?.data?.isSuccess) {
            setIsLoading(false);
            toast.success(
              response?.data?.message || "Plan is successfully Published",
              {
                position: "top-center",
                hideProgressBar: false,
                autoClose: 2000,
              }
            );
          } else {
            setIsLoading(false);
            toast.error(response?.data?.isSuccess || "", {
              position: "top-center",
              hideProgressBar: false,
              autoClose: 2000,
            });
          }
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setIsLoading(false);
      setErrorMessage("Please choose a valid picture and not a file");
    }
  };

  const handleFileChanges = (e) => {
    setFileName(fileInputRef?.current?.files[0]?.name);
    setErrorMessage("");
  };

  const handleParentClick = () => {
    console.log(fileInputRef.current.files);
    fileInputRef.current.click();
  };

  const tariffsOptions = (tariff) => {
    const tariffOptions = tariff.map((tarrif, id) => {
      return { value: tarrif.id, label: tarrif.tarrifName };
    });
    return tariffOptions;
  };

  const coverages = state.coverages.map((cover, index) => {
    return { ...cover, isChecked: true };
  });

  console.log(fileName);
  useEffect(() => {
    const stringRepresentation = state.businessRules?.split(",");
    const formattedItems = stringRepresentation
      ?.map((item) => `${item}`)
      ?.join("\n");
    setArrayAsString(formattedItems);
  }, [items, editPlanObj, setArrayAsString, reload, mode]);

  // Styles for the multiselect
  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: "#2CBCEF",
      color: "white",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "white",
    }),
  };

  return (
    <>
      <div className="flex items-center justify-center w-full min-h-screen text-sm">
        <div className="flex items-end justify-center w-full min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
            <div className="bg-white rounded-md shadow-md p-9">
              <div className="flex flex-row justify-between my-7">
                <h1 className="font-bold text-deepBrandBlue">Publish Plan</h1>
                <button onClick={() => closeModal()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div className="my-[2.5rem]">
                <InputField
                  disabled={true}
                  label="Plan Name"
                  id="planName"
                  name="planName"
                  type="text"
                  value={state?.planName}
                />

                <p className=" mt-[-1rem] mb-[0.2rem] text-[12px] text-red-500 ">
                  {errors?.planName?.message}
                </p>
              </div>

              <div className="my-[2.5rem]">
                <InputField
                  disabled={true}
                  label="Maximum Amount Per Individual"
                  id="maximumAmountPerIndividual"
                  name="maximumAmountPerIndividual"
                  type="number"
                  value={state?.maxAmountPerIndividual}
                />

                <p className=" mt-[-1rem] mb-[0.2rem] text-[12px] text-red-500 ">
                  {errors?.maximumAmountPerIndividual?.message}
                </p>
              </div>

              <div className="my-[2.5rem]">
                <InputField
                  disabled={true}
                  label="Maximum Amount Per Month For An Individual"
                  id="maximumAmountPerMonthPerIndividual"
                  name="maximumAmountPerMonthPerIndividual"
                  type="number"
                  value={state?.maxAmountPerIndividualPerMonth}
                />

                <p className=" mt-[-1rem] mb-[0.2rem] text-[12px] text-red-500 ">
                  {errors?.maximumAmountPerMonthPerIndividual?.message}
                </p>
              </div>
              <div className="px-4 py-2 border border-gray-300 rounded-lg max-full">
                <label
                  htmlFor="subscription-price"
                  className="block text-base font-semibold"
                >
                  Plan Price
                </label>
                <input
                  type="number"
                  id="subscription-price"
                  className="w-full py-2 text-lg text-gray-700 placeholder-gray-400 border-none focus:outline-none"
                  placeholder="Enter subscription price"
                  value={subscriptionPrice}
                  onChange={handleChange}
                />
              </div>
              <div className="mt-[2.5rem] mb-[1rem] rounded w-full">
                <Select
                  defaultValue={tariffsOptions(state.tarrifs)}
                  value={selectedOptions}
                  isMulti
                  isDisabled
                  isSearchable
                  allowCreateWhileLoading={false}
                  closeMenuOnSelect={false}
                  className="border-0"
                  placeholder={"Select tariff(s)..."}
                  styles={customStyles}
                />

                <p className=" mt-[-1rem] mb-[0.2rem] text-[12px] text-red-500 ">
                  {errors?.tarrifId?.message}
                </p>
              </div>
              <div className="flex w-full gap-3 mt-8 mb-10 text">
                <div className="w-full">
                  <InputField
                    label="Deductibles"
                    id="deductibles"
                    name="deductibles"
                    type="text"
                    value={state?.deductibles}
                  />

                  <p className=" mt-[-1rem] mb-[0.2rem] text-[12px] text-red-500 ">
                    {errors?.deductibles?.message}
                  </p>
                </div>
                <div className="w-full">
                  <InputField
                    label="Out-of-pocket maximum"
                    id="out_of_PocketMax"
                    name="out_of_PocketMax"
                    type="text"
                    value={state?.out_of_PocketMax}
                  />

                  <p className=" mt-[-1rem] mb-[0.2rem] text-[12px] text-red-500 ">
                    {errors?.out_of_PocketMax?.message}
                  </p>
                </div>
              </div>

              <div className="my-3 text-sm">
                <h1 className="text-[#444448] tracking-[1.26px] font-[600] ">
                  Coverage(s)
                </h1>
                <p className="mb-4 text-slate-500">Select one or more covers</p>
                <div
                  className={`flex flex-col text my-2 ${
                    coverages?.length > 3 && "h-[120px] overflow-y-scroll"
                  }  `}
                >
                  {coverages?.map((coverage, index) => {
                    return (
                      <div className="my-1" key={coverage.coverageId}>
                        <CustomCheckbox
                          isChecked={coverage.isChecked}
                          onChange={() =>
                            handleCheckboxChange(coverage.coverageId)
                          }
                          label={coverage?.coverageName}
                          required={true}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="text-xs bg-[#FAFAFA] p-4 rounded my-6 max-h-96  overflow-x-auto">
                <h1 className="text-lg font-semibold">Business Rules</h1>
                <textarea
                  className="border-0 bg-[#FAFAFA] text-sm rounded  w-full outline-0 p-3 gap-3"
                  value={arrayAsString}
                  id=""
                  rows="8"
                  readOnly
                ></textarea>
              </div>
              <div className="relative text">
                <label
                  className="absolute pl-3 font-semibold translate-y-7"
                  htmlFor="comments"
                >
                  HMO Description
                </label>
                <br />
                <textarea
                  className="w-full gap-3 px-3 text-sm bg-white border rounded outline-0 pt-7"
                  id="description"
                  name="description"
                  rows="8"
                  placeholder="Enter your description here..."
                  onChange={(e) => setHmoDescription(e.target.value)}
                ></textarea>
              </div>
              <div className="relative my-5 text">
                <label
                  className="absolute pl-3 font-semibold translate-y-7"
                  htmlFor="comments"
                >
                  Plan Description
                </label>
                <br />
                <textarea
                  className="w-full gap-3 px-3 text-sm bg-white border rounded outline-0 pt-7"
                  id="description"
                  name="description"
                  rows="8"
                  placeholder="Enter your description here..."
                  onChange={(e) => setPlanDescription(e.target.value)}
                ></textarea>
              </div>

              <div
                className={`relative flex flex-col items-center w-full px-10 pt-4 mt-8 border-2 border-dotted rounded-lg bg-sky-50 ${
                  errorMessage ? "border-red-600" : "border-sky-600"
                }  border-spacing-3 h-44`}
                onClick={() => handleParentClick()}
              >
                <input
                  onChange={handleFileChanges}
                  type="file"
                  className="absolute hidden w-full h-full"
                  ref={fileInputRef}
                  required={true}
                />
                <img src={uploadLogo} alt="" className="mb-2 text" />
                {fileInputRef?.current?.files[0] && (
                  <>
                    {errorMessage !== "" ? (
                      <h1 className="text-sm text-red-600">{errorMessage}</h1>
                    ) : (
                      <h1 className="text-sm text-green-600">
                        A sponsor logo is attached
                      </h1>
                    )}
                  </>
                )}
                {!fileInputRef?.current?.files[0] && (
                  <h1 className="text-sm text-black">
                    Click to upload Sponsor Logo
                  </h1>
                )}
                <div className="h-4"></div>
                <button
                  className={
                    "px-8 py-2 bg-white border border-sky-200 rounded-md text-sky-500 font-bold"
                  }
                >
                  Select a file
                </button>
                <div className="h-4"></div>
                <div className="text-sky-500">( Max. file size: 20MB )</div>
              </div>
              <div className="mt-8 text">
                <button
                  disabled={isLoading}
                  onClick={handleFileUpload}
                  className={`text-white ${
                    isLoading ? "bg-[#6ad1f7] " : "bg-[#2CBCEF] "
                  } w-full py-3 rounded`}
                >
                  {isLoading ? (
                    <div className="flex items-center justify-center">
                      {" "}
                      <ReloadIcon className="w-4 h-4 mr-2 animate-spin" />
                      Publishing...
                    </div>
                  ) : (
                    "Publish"
                  )}
                </button>
              </div>
              <div className="h-4"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePlanFormModal;
